<template>
  <v-card flat>
    <v-card-text>
      <v-bottom-sheet v-model="roleEditor.show" scrollable inset>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" dark v-bind="attrs" v-on="on">open</v-btn>
        </template>
        <Role
          :show="roleEditor.show"
          :employeeCode="roleEditor.employeeCode"
          @cancel="roleEditor.show = false"
          @commit="roleEditor.show = false"
        />
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>
<script>
import Role from '@/components/employees/roles/Role.vue';

export default {
  components: { Role },

  props: {},

  computed: {},

  data: () => ({
    initializing: true,

    roleEditor: {
      show: false,
      employeeCode: '7f3d9b485d184d0abd4ce98eb07c0db8',
    },
  }),
  methods: {
    onLoaded() {
      this.initializing = false;
    },
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>
