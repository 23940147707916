<template>
  <v-card flat>
    <v-card-title>
      <v-btn text class="text-none" color="warning" @click="$emit('cancel')">閉じる</v-btn>
      <v-spacer />
      <v-btn class="text-none" color="primary" @click="onCommit">決定</v-btn>
    </v-card-title>
    <v-card-text>
      <v-list-item>
        <v-chip
          class="ma-2"
          color="grey darken-2"
          v-bind="administrator.bind"
          @click="
            {
              apoValues = administrator.apo.presetValues;
              demoValues = administrator.demo.presetValues;
              leasePaymentValues = administrator.leasePayment.presetValues;
              hpCoverageValues = administrator.hpCoverage.presetValues;
              hpCreateValues = administrator.hpCreate.presetValues;
              menuValues = administrator.menu.presetValues;
            }
          "
        >
          <v-icon v-text="' mdi-shield-star'" left />
          システム管理者
        </v-chip>

        <v-chip
          class="ma-2"
          color="indigo darken-1"
          v-bind="branchManager.bind"
          @click="
            {
              apoValues = branchManager.apo.presetValues;
              demoValues = branchManager.demo.presetValues;
              leasePaymentValues = branchManager.leasePayment.presetValues;
              hpCoverageValues = branchManager.hpCoverage.presetValues;
              hpCreateValues = branchManager.hpCreate.presetValues;
              menuValues = branchManager.menu.presetValues;
            }
          "
        >
          <v-icon v-text="' mdi-shield-account'" left />
          支店長
        </v-chip>

        <v-chip
          class="ma-2"
          color="purple darken-1"
          v-bind="fieldAdviserLeader.bind"
          @click="
            {
              apoValues = fieldAdviserLeader.apo.presetValues;
              demoValues = fieldAdviserLeader.demo.presetValues;
              leasePaymentValues = fieldAdviserLeader.leasePayment.presetValues;
              hpCoverageValues = fieldAdviserLeader.hpCoverage.presetValues;
              hpCreateValues = fieldAdviserLeader.hpCreate.presetValues;
              menuValues = fieldAdviserLeader.menu.presetValues;
            }
          "
        >
          <v-icon v-text="' mdi-shield-account-outline'" left />
          FAリーダー
        </v-chip>

        <v-chip
          class="ma-2"
          color="light-blue darken-1"
          v-bind="fieldAdviser.bind"
          @click="
            {
              apoValues = fieldAdviser.apo.presetValues;
              demoValues = fieldAdviser.demo.presetValues;
              leasePaymentValues = fieldAdviser.leasePayment.presetValues;
              hpCoverageValues = fieldAdviser.hpCoverage.presetValues;
              hpCreateValues = fieldAdviser.hpCreate.presetValues;
              menuValues = fieldAdviser.menu.presetValues;
            }
          "
        >
          <v-icon v-text="'mdi-account-outline'" left />
          FA一般
        </v-chip>
      </v-list-item>

      <v-list-item>
        <v-chip
          class="ma-2"
          color="lime darken-1"
          v-bind="generalAffairLeader.bind"
          @click="
            {
              apoValues = generalAffairLeader.apo.presetValues;
              demoValues = generalAffairLeader.demo.presetValues;
              leasePaymentValues = generalAffairLeader.leasePayment.presetValues;
              hpCoverageValues = generalAffairLeader.hpCoverage.presetValues;
              hpCreateValues = generalAffairLeader.hpCreate.presetValues;
              menuValues = generalAffairLeader.menu.presetValues;
            }
          "
        >
          <v-icon v-text="' mdi-account-credit-card'" left />
          業務管理者
        </v-chip>
        <v-chip
          class="ma-2"
          color="orange darken-4"
          v-bind="generalAffair.bind"
          @click="
            {
              apoValues = generalAffair.apo.presetValues;
              demoValues = generalAffair.demo.presetValues;
              leasePaymentValues = generalAffair.leasePayment.presetValues;
              hpCoverageValues = generalAffair.hpCoverage.presetValues;
              hpCreateValues = generalAffair.hpCreate.presetValues;
              menuValues = generalAffair.menu.presetValues;
            }
          "
        >
          <v-icon v-text="' mdi-account-edit'" left />
          業務一般
        </v-chip>
      </v-list-item>

      <div class="d-flex">
        <v-divider />
        <v-icon class="ml-2" v-text="expanded ? 'mdi-chevron-down' : 'mdi-chevron-up'" @click="expanded = !expanded" />
      </div>
      <v-slide-y-transition>
        <v-card v-if="expanded" flat>
          <v-card-text>
            <v-subheader class="red--text">アポイント</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of apoRoles" :key="index">
                  <v-checkbox v-model="apoValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-subheader class="red--text">デモ</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of demoRoles" :key="index">
                  <v-checkbox v-model="demoValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-subheader class="red--text">リース審査関係</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of leasePaymentRoles" :key="index">
                  <v-checkbox v-model="leasePaymentValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-subheader class="red--text">HP取材</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of hpCoverageRoles" :key="index">
                  <v-checkbox v-model="hpCoverageValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-subheader class="red--text">HP作成</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of hpCreateRoles" :key="index">
                  <v-checkbox v-model="hpCreateValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <v-divider />

            <v-subheader class="red--text">メニュー</v-subheader>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2" md="3" v-for="(role, index) of menuRoles" :key="index">
                  <v-checkbox v-model="menuValues" :color="role.color" :value="role.value" hide-details dense>
                    <template v-slot:label>
                      <span class="text-caption">{{ role.label }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>
<script>
const Colors = {
  Read: 'light-blue',
  Create: 'teal  lighten-2',
  Update: 'amber',
  Delete: 'pink lighten-2',
};

// function arrayEqual(a, b) {
//   // 配列か？
//   if (!Array.isArray(a)) return false;
//   if (!Array.isArray(b)) return false;
//   // 個数が異なっているか？
//   if (a.length != b.length) return false;

//   // bの中に存在しない値があるか？
//   for (let i = 0; i < b.length; ++i) {
//     if (!a.includes(b[i])) return false;
//   }

//   // bの中に存在しない値があるか？
//   for (let i = 0; i < a.length; ++i) {
//     if (!b.includes(a[i])) return false;
//   }
//   return true;
// }

function arrayCover(a, b) {
  // 配列か？
  if (!Array.isArray(a)) return false;
  if (!Array.isArray(b)) return false;

  //
  for (let i = 0; i < a.length; ++i) {
    if (!b.includes(a[i])) return false;
  }
  return true;
}

class AgentData {
  constructor(presetValues) {
    this.presetValues = presetValues;
    this.selectedValues = [];
  }

  get selected() {
    //return false;
    // presetを満たしていればOKとする
    return arrayCover(this.presetValues, this.selectedValues);
    // presetとマッチしていればOK（つまり全選択時は下位を評価しない）
    // return arrayEqual(this.presetValues, this.selectedValues);
  }
}

class Agent {
  constructor(apoPresets, demoPresets, leasePaymentPresets, hpCoverageRolesPresets, hpCreateRolesPresets, menuRolesPresets) {
    //
    this.apo = new AgentData(apoPresets);
    this.demo = new AgentData(demoPresets);
    this.leasePayment = new AgentData(leasePaymentPresets);
    this.hpCoverage = new AgentData(hpCoverageRolesPresets);
    this.hpCreate = new AgentData(hpCreateRolesPresets);
    this.menu = new AgentData(menuRolesPresets);
  }

  get apoValues() {
    return this.apo.selectedValues;
  }
  set apoValues(value) {
    this.apo.selectedValues = value;
  }

  get demoValues() {
    return this.demo.selectedValues;
  }
  set demoValues(value) {
    this.demo.selectedValues = value;
  }
  get leasePaymentValues() {
    return this.leasePayment.selectedValues;
  }
  set leasePaymentValues(value) {
    this.leasePayment.selectedValues = value;
  }

  get hpCoverageValues() {
    return this.hpCoverage.selectedValues;
  }
  set hpCoverageValues(value) {
    this.hpCoverage.selectedValues = value;
  }

  get hpCreateValues() {
    return this.hpCreate.selectedValues;
  }
  set hpCreateValues(value) {
    this.hpCreate.selectedValues = value;
  }
  get menuValues() {
    return this.menu.selectedValues;
  }
  set menuValues(value) {
    this.menu.selectedValues = value;
  }

  get selected() {
    if (!this.apo.selected) return false;
    if (!this.demo.selected) return false;
    if (!this.leasePayment.selected) return false;
    if (!this.hpCoverage.selected) return false;
    if (!this.hpCreate.selected) return false;
    if (!this.menu.selected) return false;
    return true;
  }

  get bind() {
    if (this.selected) return { textColor: 'white' };
    return { outlined: true };
  }
}

export default {
  components: {},

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    employeeCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    apoValues: {
      get() {
        return this.apo;
      },
      set(newValue) {
        this.apo = newValue;
        //
        this.administrator.apoValues = newValue;
        this.branchManager.apoValues = newValue;
        this.fieldAdviserLeader.apoValues = newValue;
        this.fieldAdviser.apoValues = newValue;
        this.generalAffairLeader.apoValues = newValue;
        this.generalAffair.apoValues = newValue;
      },
    },
    demoValues: {
      get() {
        return this.demo;
      },
      set(newValue) {
        this.demo = newValue;
        //
        this.administrator.demoValues = newValue;
        this.branchManager.demoValues = newValue;
        this.fieldAdviserLeader.demoValues = newValue;
        this.fieldAdviser.demoValues = newValue;
        this.generalAffairLeader.demoValues = newValue;
        this.generalAffair.demoValues = newValue;
      },
    },

    leasePaymentValues: {
      get() {
        return this.leasePayment;
      },
      set(newValue) {
        this.leasePayment = newValue;
        //
        this.administrator.leasePaymentValues = newValue;
        this.branchManager.leasePaymentValues = newValue;
        this.fieldAdviserLeader.leasePaymentValues = newValue;
        this.fieldAdviser.leasePaymentValues = newValue;
        this.generalAffairLeader.leasePaymentValues = newValue;
        this.generalAffair.leasePaymentValues = newValue;
      },
    },

    hpCoverageValues: {
      get() {
        return this.hpCoverage;
      },
      set(newValue) {
        this.hpCoverage = newValue;
        //
        this.administrator.hpCoverageValues = newValue;
        this.branchManager.hpCoverageValues = newValue;
        this.fieldAdviserLeader.hpCoverageValues = newValue;
        this.fieldAdviser.hpCoverageValues = newValue;
        this.generalAffairLeader.hpCoverageValues = newValue;
        this.generalAffair.hpCoverageValues = newValue;
      },
    },

    hpCreateValues: {
      get() {
        return this.hpCreate;
      },
      set(newValue) {
        this.hpCreate = newValue;
        //
        this.administrator.hpCreateValues = newValue;
        this.branchManager.hpCreateValues = newValue;
        this.fieldAdviserLeader.hpCreateValues = newValue;
        this.fieldAdviser.hpCreateValues = newValue;
        this.generalAffairLeader.hpCreateValues = newValue;
        this.generalAffair.hpCreateValues = newValue;
      },
    },

    menuValues: {
      get() {
        return this.menu;
      },
      set(newValue) {
        this.menu = newValue;
        //
        this.administrator.menuValues = newValue;
        this.branchManager.menuValues = newValue;
        this.fieldAdviserLeader.menuValues = newValue;
        this.fieldAdviser.menuValues = newValue;
        this.generalAffairLeader.menuValues = newValue;
        this.generalAffair.menuValues = newValue;
      },
    },
  },

  data: () => ({
    initializing: true,

    expanded: true,
    // プリセットとしてどの権限を与えるか設定
    administrator: new Agent([1, 2, 3, 4], [1, 2, 3, 4], [1, 2, 3, 4], [1, 2, 3, 4], [], [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    branchManager: new Agent([1, 2, 3], [1, 2, 4], [], [1, 2, 3, 4], [], [1, 2, 3, 4, 6, 7, 8, 9, 10, 12]),
    fieldAdviserLeader: new Agent([1, 2], [1, 2, 4], [], [1, 2, 3, 4], [], [1, 2, 3, 4, 7, 8, 9]),
    fieldAdviser: new Agent([1, 2], [1, 2], [], [1, 2, 3, 4], [], [1, 2, 3, 4]),
    generalAffairLeader: new Agent([], [], [1, 2, 3, 4], [], [], [3, 4, 7, 8, 9, 10, 12]),
    generalAffair: new Agent([], [], [1, 2, 3, 4], [], [], [3, 4, 7, 8, 9, 10]),

    apo: [],
    demo: [],
    leasePayment: [],
    hpCoverage: [],
    hpCreate: [],
    menu: [],

    apoRoles: [
      { label: 'アポ一覧', value: 1, color: Colors.Read },
      { label: '新規アポ取得', value: 2, color: Colors.Create },
      { label: 'アポ編集', value: 3, color: Colors.Update },
      { label: 'アポ削除', value: 4, color: Colors.Delete },
    ],
    demoRoles: [
      { label: 'デモ一覧', value: 1, color: Colors.Read },
      { label: '新規デモ取得', value: 2, color: Colors.Create },
      { label: 'デモ編集', value: 3, color: Colors.Update },
      { label: 'デモ削除', value: 4, color: Colors.Delete },
    ],
    leasePaymentRoles: [
      { label: 'リース一覧', value: 1, color: Colors.Read },
      { label: 'リース審査結果登録', value: 2, color: Colors.Update },
      { label: 'リース開始登録', value: 3, color: Colors.Update },
      { label: 'リース入金登録', value: 4, color: Colors.Update },
    ],
    hpCoverageRoles: [
      { label: '導入日程登録待ち一覧', value: 1, color: Colors.Read },
      { label: '導入・取材待ち一覧', value: 2, color: Colors.Read },
      { label: '導入取材日程報告', value: 3, color: Colors.Create },
      { label: '導入取材報告', value: 4, color: Colors.Update },
    ],
    hpCreateRoles: [],

    menuRoles: [
      { label: 'ダッシュボード', value: 1, color: Colors.Read },
      { label: '営業成績確認', value: 2, color: Colors.Read },
      { label: 'フォーム', value: 3, color: Colors.Read },
      { label: '営業フロー', value: 4, color: Colors.Read },
      { label: '施設管理', value: 5, color: Colors.Read },
      { label: 'スケジュール', value: 6, color: Colors.Read },
      { label: '売上進捗', value: 7, color: Colors.Read },
      { label: '営業ポイント成績表', value: 8, color: Colors.Read },
      { label: '営業マージン集計表', value: 9, color: Colors.Read },
      { label: '売上伝票', value: 10, color: Colors.Read },
      { label: 'マスタ', value: 11, color: Colors.Read },
      { label: '受注物件状況', value: 12, color: Colors.Read },
    ],
  }),
  methods: {
    onLoaded() {
      this.initializing = false;

      this.apo = [];
      this.demo = [];
      this.leasePayment = [];
      this.hpCoverage = [];
      this.hpCreate = [];
      this.menu = [];

      this.get('employee', { employeeCode: this.employeeCode }).then((success) => {
        console.log(success.data.meta);
        let data = success.data;
        let role = data.meta.role;
        if (role == null) return; // なくてもここで更新させる
        //
        this.apo = role.apo;
        this.demo = role.demo;
        this.leasePayment = role.leasePayment;
        this.hpCoverage = role.hpCoverage;
        this.hpCreate = role.hpCreate;
        this.menu = role.menu;
      });
    },

    onCommit() {
      let request = {
        employeeCode: this.employeeCode,
        apo: this.apo,
        demo: this.demo,
        leasePayment: this.leasePayment,
        hpCoverage: this.hpCoverage,
        hpCreate: this.hpCreate,
        menu: this.menu,
      };

      this.put('employee/role', request).then((success) => {
        console.log({ success });

        this.$emit('commit');
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>
